<template>
  <div class="prod_wrap">
    <ul class="prod_nav">
      <li v-for="(item,index) in navArr" :class="{activeImg:current === index}" @mousemove="mouseChange(index)" :key="index">
        <p class="img_box">
          <img :src="item.icon" alt="">
        </p>
        <p>{{item.name}}</p>
        <p class="down_box" v-if="current == index">
          <img src="@/assets/img/prod_down.png" alt="">
        </p>
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 0">
      <li v-for="(item,index) in arr1" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 1">
      <li v-for="(item,index) in arr2" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 2">
      <li v-for="(item,index) in arr3" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 3">
      <li v-for="(item,index) in arr4" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 4">
      <li v-for="(item,index) in arr5" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 5">
      <li v-for="(item,index) in arr6" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 6">
      <li v-for="(item,index) in arr7" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
    <ul class="prod_imgs" v-if="current === 7">
      <li v-for="(item,index) in arr8" :key="index">
        <img :src="item" alt="">
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "prodMix",
  methods:{
    mouseChange(index) {
      this.current = index;
    }
  },
  data() {
    return {
      current:0,
      arr1:["https://qiniu.youjiamall.cn/yjpc/prod11.png",
        "https://qiniu.youjiamall.cn/yjpc/prod12.png",
        "https://qiniu.youjiamall.cn/yjpc/prod13.png",
        "https://qiniu.youjiamall.cn/yjpc/prod14.png",
        "https://qiniu.youjiamall.cn/yjpc/prod15.png"],
      arr2:["https://qiniu.youjiamall.cn/yjpc/prod21.png",
        "https://qiniu.youjiamall.cn/yjpc/prod22.png",
        "https://qiniu.youjiamall.cn/yjpc/prod23.png",
        "https://qiniu.youjiamall.cn/yjpc/prod24.png",
        "https://qiniu.youjiamall.cn/yjpc/prod25.png"],
      arr3:["https://qiniu.youjiamall.cn/yjpc/prod31.png",
        "https://qiniu.youjiamall.cn/yjpc/prod32.png",
        "https://qiniu.youjiamall.cn/yjpc/prod33.png",
        "https://qiniu.youjiamall.cn/yjpc/prod34.png",
        "https://qiniu.youjiamall.cn/yjpc/prod35.png"],
      arr4:["https://qiniu.youjiamall.cn/yjpc/prod41.png",
        "https://qiniu.youjiamall.cn/yjpc/prod422.png",
        "https://qiniu.youjiamall.cn/yjpc/prod43.png",
        "https://qiniu.youjiamall.cn/yjpc/prod44.png",
        "https://qiniu.youjiamall.cn/yjpc/prod45.png"],
      arr5:["https://qiniu.youjiamall.cn/yjpc/prod51.png",
        "https://qiniu.youjiamall.cn/yjpc/prod52.png",
        "https://qiniu.youjiamall.cn/yjpc/prod53.png",
        "https://qiniu.youjiamall.cn/yjpc/prod54.png",
        "https://qiniu.youjiamall.cn/yjpc/prod55.png"],
      arr6:["https://qiniu.youjiamall.cn/yjpc/prod61.png",
        "https://qiniu.youjiamall.cn/yjpc/prod62.png",
        "https://qiniu.youjiamall.cn/yjpc/prod63.png",
        "https://qiniu.youjiamall.cn/yjpc/prod64.png",
        "https://qiniu.youjiamall.cn/yjpc/prod65.png"],
      arr7:["https://qiniu.youjiamall.cn/yjpc/prod71.png",
        "https://qiniu.youjiamall.cn/yjpc/prod72.png",
        "https://qiniu.youjiamall.cn/yjpc/prod73.png",
        "https://qiniu.youjiamall.cn/yjpc/prod74.png",
        "https://qiniu.youjiamall.cn/yjpc/prod75.png"],
      arr8:["https://qiniu.youjiamall.cn/yjpc/prod81.png",
        "https://qiniu.youjiamall.cn/yjpc/prod82.png",
        "https://qiniu.youjiamall.cn/yjpc/prod83.png",
        "https://qiniu.youjiamall.cn/yjpc/prod84.png",
        "https://qiniu.youjiamall.cn/yjpc/prod85.png"],
      navArr:[{
          name:'米面粮油',
          icon:require('@/assets/img/prod_1.png'),
      },{
        name:'水果生鲜',
        icon:require('@/assets/img/prod_2.png'),
      },{
        name:'营养滋补',
        icon:require('@/assets/img/prod_3.png'),
      },{
        name:'茶叶糕点',
        icon:require('@/assets/img/prod_4.png'),
      },{
        name:'生活电器',
        icon:require('@/assets/img/prod_5.png'),
      },{
        name:'厨房用具',
        icon:require('@/assets/img/prod_6.png'),
      },{
        name:'日用百货',
        icon:require('@/assets/img/prod_7.png'),
      },{
        name:'箱包家纺',
        icon:require('@/assets/img/prod_8.png'),
      }]
    }
  }
}
</script>

<style scoped>
.prod_wrap .prod_nav {
  padding: 0 65px 30px  65px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prod_nav li {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #C1C1C1;
}
.prod_nav li img{
  width: 60px;
  height: 60px;
}
.prod_nav .activeImg img {
  width: 72px;
  height: 72px;
}
.activeImg {

  font-size: 16px !important;
  color: #007858 !important;
}
.activeImg .img_box {
  background: #007858;
}
.prod_nav li p {
  margin-top: 9px;
  text-align: center;
}
.prod_nav .activeImg p {
  margin-top: 5px;
}
.activeImg .img_box {
  width: 78px;
  height: 78px;
}
.activeImg .img_box img {
  transform: scale(.5);
}
.prod_imgs {
  display: flex;
  /*margin-top: 34px;*/
  padding:0 0px 0 60px;
  justify-content: space-between;
  box-sizing: border-box;
}
.prod_imgs li {
  margin-right: 74px;
}

.prod_imgs img {
  width: 160px;
  height: 160px;
  /*transform: scale(.3);*/
}

.img_box {
  display: flex;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #ADAFB2;
}
.img_box img {
  margin: auto;
  transform: scale(.4);
}
.down_box {
  display: flex;
  width: 100%;
  height: 26px;
}
.down_box img {
  margin: auto;
  width: 55px !important;
  height: 100% !important;
}
</style>
