<template>
  <div class="made_wrap">
      <div class="made_title">
        <p>量身定制<span>CUSTOMIZED</span></p>
        <span>礼包福利量身定制</span>
        <div class="tit_bd"></div>
      </div>
      <div class="made_mai">
        <div class="mai_left">
          <p>
            每个节日都是企业与员工在情感上的升华，我们可根据不同企业需求、节日，为企业量身定制卡券样式、发放形式、企业banner、专属H5、头像等，帮助企业在预算范围内以最高品质的产品温暖员工。
          </p>
        </div>
        <div class="mai_right">
          <img src="https://qiniu.youjiamall.cn/jirri_bg1.png" alt="">
        </div>
      </div>


<!--      <ul class="nav_box">-->
<!--        <li class="let_btn">1</li>-->
<!--        <li v-for="(item,index) in nav" @mousemove="mouseChange(index)" :class="{activeNav:current === index}">{{item}}</li>-->
<!--        <li class="rig_btn">2</li>-->
<!--      </ul>-->
  </div>
</template>

<script>
export default {
  name: "holiMade",
  data() {
    return {
      current:0,
      nav:["元旦","春节","妇女节","夏送清凉","端午节","儿童节","中秋节","国庆节","元旦","春节","妇女节","夏送清凉","端午节","儿童节","中秋节","国庆节"]
    }
  },
  methods:{
    mouseChange(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped>
.made_wrap {
  padding: 57px 120px 102px;
  box-sizing: border-box;
  background: #F4F7FA;
}
.made_title {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: left;
}
.made_title span {
  font-size: 30px;
  font-family: Arial;
  font-weight: 400;
  color: #EEEEEE;
  display: inline-block;
  margin-left: 8px;
}
.made_title>span {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-left: 0;
}
.tit_bd {
    width: 157px;
    height: 2px;
    background: #007858;
}
.made_mai {
    position: relative;
    display: flex;
    margin-top: 60px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3A3A3A;
}

  .mai_left {
    width: 630px;
    height: 232px;
    background: #FFFFFF;
    position: relative;
    box-sizing: border-box;

    box-shadow: 0px 18px 32px 0px #E9EEF4;
  }
  .mai_left>p {
    position: absolute;
    left: 38px;
    top: 42%;
    transform: translateY(-50%);
    width: 266px;
    height: 88px;
    color: #666;
    line-height: 24px;
    font-size: 14px;
  }
  .mai_right {
    width: 100%;
  }
  .mai_right img {
    height: 313px;
    width: 853px;
    transform: scale(0.9);
    position: absolute;
    right: 50px;
    top: -30px;
  }
  .nav_box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    margin-top: 70px;
    padding-left:400px;
    width: 720px;
    height: 56px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-size: 15px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    cursor: pointer;
    box-sizing: border-box;
    overflow-x: auto;

    white-space: nowrap;

  }
  .nav_box li {
    margin-right: 20px;
  }
  .activeNav {
    color: #007858;
  }
</style>
