<template>
    <div>

      <div class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <img src="https://qiniu.youjiamall.cn/yjpc/banner1.png" alt="">
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <!-- 通用提货券-->
      <div class="mai_wrap">
        <pubBoxTit title="通用提货券" ltit="CONVENIENT" paddingTop="30"></pubBoxTit>
        <div class="mai_box tyMain">
          <holiConven pageType="th"></holiConven>
        </div>
      </div>
      <!--   产品组合   -->
      <div class="mai_wrap prd_mai">
        <pubBoxTit title="产品组合" ltit="COMBINATION" paddingTop="50"></pubBoxTit>
        <div class="mai_box">
          <pordMix></pordMix>
        </div>
      </div>

      <!--   量身定制   -->
      <div class="custom_made">
        <holiMade></holiMade>
      </div>


      <!--    产品服务  -->
      <div class="mai_wrap">
        <pubBoxTit title="产品服务" ltit="SERVICE" paddingTop="46"></pubBoxTit>
        <div class="mai_box">
            <HoliProd></HoliProd>
        </div>
      </div>

      <!--    甄选优质服务商  -->
      <div class="mai_wrap">
        <pubBoxTit title="甄选优质服务商"  ltit="PROVIDER" paddingTop="20"></pubBoxTit>
        <div>
          <weUser arr-type="1"></weUser>
        </div>
      </div>

      <pubFormInput></pubFormInput>
    </div>
</template>

<script>
import pubBoxTit from '@/components/pubBoxTit.vue'
import holiConven from '@/components/HoliComponents/holiConven.vue'
import HoliProd from '@/components/HoliComponents/holiProd.vue'
import weUser from '@/components/hmComponent/weUser.vue'
import pubFormInput from "@/components/pubFormInput";
import pordMix from '@/components/HoliComponents/prodMix.vue';
import holiMade from "@/components/HoliComponents/holiMade";
export default {
    components: {
      pubBoxTit,
      holiConven,
      HoliProd,
      weUser,
      pubFormInput,
      pordMix,
      holiMade
    },
  metaInfo () {
    return {
      title:"优加惠品",
      meta: [{
        name: 'keyWords',
        content: '优加惠品,福利,年节慰问品,电影,蛋糕福利'
      },
        {
          name: 'description',
          content: '优加惠品,优加,福利,慰问品,年节慰问品,电影,提货券,蛋糕卡,电影卡,生日卡,生日券,蛋糕券,全福卡,全福券,蛋糕,电影福利,蛋糕福利,元宵节慰问品,龙抬头慰问品,端午慰问品节,七夕节慰问品,三八妇女节,五一劳动节,中秋节慰问品,重阳节慰问品,下元节慰问品,冬至节慰问品,腊八节慰问品,小年慰问品,除夕慰问品,春节慰问品,元宵节,龙抬头,端午节,七夕节,中秋节,重阳节,三八妇女节,五一劳动节,下元节,冬至节,腊八节,小年,员工福利,员工体检,企业团险,员工团建活动,夏送清凉,冬送温暖'
        }],
      link: [{ // set link
        rel: 'asstes',
        href: 'https://assets-cdn.github.com/'
      }]
    }
  },
    data() {
        return {
        };
    },

    methods: {}
}

</script>
<style scoped>
.mai_box {
  padding: 65px 134px 40px;
  box-sizing: border-box;
  background:#fff;
}
/*.swiper {*/
/*  height: 524px;*/
/*}*/
/*.swiper img{*/
/*  width: 100%;*/
/*  height: 524px;*/
/*}*/
.tyMain {
  background: url('https://qiniu.youjiamall.cn/yjpc/holiBg.png')no-repeat;
  background-size: 100% 100%;
}

</style>
