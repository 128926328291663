<template>
  <div class="mai_content">
    <ul>
      <li v-for="(item,index) in cardArr" @mouseleave="mouseChange(-1)"  @mousemove="mouseChange(index)"  :key="index">
        <div v-if="current === index"><img :src="item.selIcon" alt=""></div>
        <div v-else><img :src="item.icon" alt=""></div>
        <div>
          <p>{{item.title}}</p>
          <span>{{item.tips}}</span>
          <span v-if="item.title === '快速配送'" style="color:#fff;">ference</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "holiConven",
  data() {
    return {
      current:-1,
      cardArr:[{
        title:'卡样设计',
        tips:'Card sample design',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw1.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_1.png',
      },{
        title:'发放形式',
        tips:'Distribution form',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw2.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_2.png',
      },{
        title:'满足个性',
        tips:'Satisfy personality',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw3.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_3.png',
      },{
        title:'智能兑换',
        tips:'Smart exchange',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw4.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_4.png',
      },{
        title:'品类众多',
        tips:'Numerous categories',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw5.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_5.png',
      },{
        title:'全球采购',
        tips:'Global sourcing',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw6.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_6.png',
      },{
        title:'企采优惠',
        tips:'Enterprise preference',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw7.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_7.png',
      },{
        title:'便捷送礼',
        tips:'Convenient gift',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw8.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_8.png',
      },{
        title:'全国通用',
        tips:'National General',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw9.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_9.png',
      },{
        title:'灵活开票',
        tips:'Flexible Invoicing',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw10.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_10.png',
      },{
        title:'快速配送',
        tips:'Fast delivery',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw11.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_11.png',
      },{
        title:'人工客服',
        tips:'Customer service',
        selIcon:'https://qiniu.youjiamall.cn/yjpc/icon_fw12.png',
        icon:'https://qiniu.youjiamall.cn/yjpc/icon_fw_12.png',
      }]
    }
  },
  methods:{
    mouseChange(index) {
      this.current = index;
    }
  }
}
</script>

<style scoped>
.mai_content ul {
  display: flex;
  flex-wrap: wrap;

}
.mai_content li {
  cursor: pointer;
  width: 279px;
  height: 122px;
  border: 1.2px solid #E9EFF4;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 9px;
  margin-bottom: 10px;
}

.mai_content li:hover {
  border: 0 !important;
  box-shadow: 0px 5px 22px 0px rgba(0,0,0,0.1);
}
.mai_content li:hover {
  color: #007858;
}
.mai_content li:hover span{
  color: #007858;
}
.liActive {
  border: 0 !important;
  box-shadow: 0px 5px 22px 0px rgba(0,0,0,0.1);
}
.liActive p {
  color: #007858;
}


.mai_content span {
  font-size: 13px;
  font-family: jiangxizhuokai;
  font-weight: 400;
  color: #666666;
}
.mai_content img {
  /*width: 32px;*/
  /*height: 27px;*/
  margin-right: 27px;
}

</style>
